import React, { useEffect } from "react";
import { useAuth } from "./utilities/authenticate";
import { Form, Button, Card } from "react-bootstrap";
// import { useHistory } from "react-router-dom";

import { PageView, Event } from "./Tracking/index";

export default function Login(props) {
  useEffect(() => {
    PageView(props.history.location.pathname);
  });
  // comes from provider component(AuthProvider) in App.js
  const { login, logout, currentUser } = useAuth();
  //   const [error, setError] = useState("");
  //   const [loading, setLoading] = useState(false);
  // const history = useHistory();

  async function handleSubmit(e) {
    // Event("Button", "Clicked Log In With Google Button", "UserLogin_Component");

    e.preventDefault();

    try {
      //   setError("");
      //   setLoading(true);
      await login();
      props.history.push("/search");
    } catch {
      //setError("Failed to log in");
      //console.log("Error occurred!");
    }

    //setLoading(false);
  }

  return (
    <div className="mx-auto w-50 mt-4">
      <Card>
        <Card.Body>
          <h4 className="text-center mb-4">Your new destination for fashion inspiration</h4>
          <Form onSubmit={handleSubmit}>
            <Button
              disabled={null}
              className="w-100"
              type="submit"
              onClick={() =>
                Event(
                  "Button",
                  "Clicked Log In With Google Button",
                  "UserLogin_Component"
                )
              }
            >
              Login with Google
            </Button>
          </Form>
          <Button
            className="w-100 mt-3"
            variant="danger"
            type="button"
            onClick={() => {
              Event("Button", "Clicked Sign Out Button", "UserLogin_Component");
              logout();
            }}
          >
            Sign Out
          </Button>
        </Card.Body>
      </Card>
      <div className="mt-3">
        {currentUser ? (
          <div className="alert alert-success" role="alert">
            Welcome, thank you for logging in{" "}
            <strong>{currentUser.displayName}</strong>! Would you like to start
            a new{" "}
            <a
              href="#/search"
              onClick={() =>
                Event("Link", "Clicked Search link", "UserLogin_Component")
              }
            >
              search
            </a>
            ?
          </div>
        ) : (
          <div className="alert alert-info-welcome" role="alert">
            We only collect your email id, because we like to be with humans like you :)
          </div>
        )}
      </div>
    </div>
  );
}
