import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import Product from "./utilities/Product";
import Dialbar from "./Dialbar";
import { useHistory, Link } from "react-router-dom";

import { Event } from "./Tracking/index";
import ProgressBar from "./ProgressBar";

function Alternatives({ data }) {
  const numberOfProducts = data.length;
  const history = useHistory();

  const [index, setIndex] = useState(0);
  const [product, setProduct] = useState(data[0]);
  const [dialBarValue, setDialBarValue] = useState(3);
  const [productsWithScores, setProductsWithScores] = useState([]);

  function handleNext() {
    Event("Button", "Clicked Next Button", "Alternatives_Component");
    setIndex(index + 1);
  }

  function handlePrev() {
    Event("Button", "Clicked Previous Button", "Alternatives_Component");
    setIndex(index - 1);
  }

  const addScoresIntoProducts = useCallback(() => {
    const products = [...data];

    const result = products.map(function (product) {
      const o = Object.assign({}, Object.values(product)[0]);
      o.scores = 3;
      return o;
    });
    setProductsWithScores(result);
    // console.log("result", result);
  }, []);

  useEffect(() => {
    addScoresIntoProducts();
  }, [addScoresIntoProducts]);

  const updateProductsScores = useCallback(() => {
    if (productsWithScores.length > 0) {
      // console.log("updated product", productsWithScores);
      productsWithScores[index]["scores"] = dialBarValue;
      // console.log("updating", productsWithScores[index].scores);
      setProductsWithScores(productsWithScores);
      // console.log("updated products", productsWithScores);
    }
  }, [dialBarValue]);

  function handleDialBarChange(score) {
    setDialBarValue(score);
  }

  function handleRedirect() {
    Event("Button", "Clicked Submit Button", "Alternatives_Component");
    // console.log("final data", productsWithScores);
    history.push({
      pathname: "/summary",
      summaryProps: { productsWithScores },
    });
  }

  useEffect(() => {
    updateProductsScores();
  }, [updateProductsScores]);

  useEffect(() => {
    setProduct(Object.values(data[index])[0]);
    if (productsWithScores.length > 0) {
      setDialBarValue(productsWithScores[index].scores);
    }
    // console.log("data", data);
  }, [index]);

  return (
    <div>
      <div className="container" style={{ width: "90%" }}>
        <ProgressBar numbers={numberOfProducts} index={index} />
        {/* needs List component*/}
        <div className="row d-flex justify-content-center">
          <div className="card survey-card shadow">
            <div className="card-body">
              <div className="card-text">
                {product ? <Product data={product} /> : null}
              </div>
              <div className="card-text">
                <Dialbar onChange={handleDialBarChange} value={dialBarValue} />
              </div>
              <div className="survey-btn">
                <Button
                  style={{ marginRight: "1rem" }}
                  variant="danger"
                  size="lg"
                  className="survey-cta"
                  onClick={index > 0 ? handlePrev : null}
                >
                  Previous
                </Button>
                {index === numberOfProducts - 1 ? (
                  // <Link
                  //   className="btn btn-primary p-2"
                  //   to={{
                  //     pathname: `/summary`,
                  //     summaryProps: {
                  //       productsWithScores,
                  //     },
                  //   }}
                  //   // onClick={() =>
                  //   //   Event(
                  //   //     "Button",
                  //   //     "Clicked Submit Button",
                  //   //     "Alternatives_Component"
                  //   //   )
                  //   // }
                  // >
                  //   Submit
                  // </Link>
                  <Button
                    className="btn btn-primary p-2"
                    onClick={handleRedirect}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    size="lg"
                    className="survey-cta"
                    onClick={index < numberOfProducts ? handleNext : null}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alternatives;
