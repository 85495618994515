import React from "react";

function ProgressBar({ numbers, index }) {
  function determineBarWidth() {
    return (100 / numbers) * (index + 1);
  }

  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        // aria-valuenow="25"
        // aria-valuemin="0"
        // aria-valuemax="100"
        style={{ width: `${determineBarWidth()}%` }}
      >
        {index + 1}/{numbers}
      </div>
    </div>
  );
}

export default ProgressBar;
