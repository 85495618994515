import React, { useState, useEffect, useRef } from "react";
import "../utilities/dropdown.scss";

import { Event } from "../Tracking/index";

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

function Dropdown({
  title,
  reasons,
  multiSelect = false,
  handleSelectedReasons,
  style,
}) {
  const [open, setOpen] = useState(false);
  const [reasonSelection, setReasonSelection] = useState([]);
  const [reasonTitle, setReasonTitle] = useState(title);
  const toggle = () => setOpen(!open);

  async function handleOnClick(reason) {
    if (!reasonSelection.some((current) => current.id === reason.id)) {
      if (!multiSelect) {
        setReasonSelection([reason]);
        setReasonTitle(reason.value);
      } else if (multiSelect) {
        setReasonSelection([...reasonSelection, reason]);
      }
    } else {
      let selectionAfterRemoval = reasonSelection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        (current) => current.id !== reason.id
      );
      setReasonSelection([...selectionAfterRemoval]);
    }
  }

  useEffect(() => {
    handleSelectedReasons(reasonSelection);
  }, [reasonSelection]);

  function isItemInSelection(reason) {
    if (reasonSelection.some((current) => current.id === reason.id)) {
      return true;
    }
    return false;
  }

  let domNode = useClickOutside(() => {
    setOpen(false);
  });

  return (
    <div className="dd-wrapper" ref={domNode} style={style ? style : null}>
      <div
        tabIndex={0}
        className="dd-header"
        role="button"
        onKeyPress={() => {
          Event("KeyPress", "Key Pressed Toggle", "Dropdown_Component");
          toggle(!open);
        }}
        onClick={() => {
          Event("Mouse Click", "Mouse Clicked Toggle", "Dropdown_Component");
          toggle(!open);
        }}
      >
        <div className="dd-header__title">
          <p
            className="dd-header__title--bold"
            style={{ paddingTop: "0.5rem"}}
          >
            {reasonTitle}
          </p>
        </div>
        <div className="dd-header__action" style={{ paddingTop: "0.7rem" }}>
          <p>
            {open ? (
              <ion-icon name="caret-up"></ion-icon>
            ) : (
              <ion-icon name="caret-down"></ion-icon>
            )}
          </p>
        </div>
      </div>
      {open && (
        <ul className="dd-list">
          {reasons.map((reason) => (
            <li className="dd-list-item" key={reason.id}>
              <button
                type="button"
                onClick={() => {
                  Event("Button", "Clicked Reason", "Dropdown_Component");
                  handleOnClick(reason);
                }}
              >
                <span style={{ fontSize: "0.8rem" }}>{reason.value}</span>
                <span>
                  {isItemInSelection(reason) && (
                    <ion-icon name="checkmark-sharp"></ion-icon>
                  )}
                </span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
