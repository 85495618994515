import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Product from "./utilities/Product";
import Dropdown from "./utilities/Dropdown";
// import { useLocation } from "react-router-dom";
import { useAuth } from "./utilities/authenticate";
import { initGA, PageView, Event } from "./Tracking/index";

import http from "../services/httpService";
// import axios from "axios";

const likedReasons = [
  {
    id: 1,
    value: "Pricing appropriate",
  },
  {
    id: 2,
    value: "Suits my style and taste",
  },
  {
    id: 3,
    value: "I like the colour/cut/material",
  },
  {
    id: 4,
    value: "Item is similar to what I want",
  },
  {
    id: 5,
    value: "I just loved it",
  },
];

const dislikedReasons = [
  {
    id: 1,
    value: "Price high/low",
  },
  {
    id: 2,
    value: "Not my taste and style",
  },
  {
    id: 3,
    value: "Dislike the colour/cut/material",
  },
  {
    id: 4,
    value: "Not the same item category",
  },
  {
    id: 5,
    value: "Item not similar ",
  },
];

export default function Summary(props) {
  // get current user details
  const { currentUser } = useAuth();
  //currentUser.email

  // const location = useLocation();

  const allItemsWithScores =
    props.location.summaryProps.productsWithScores.length > 0
      ? props.location.summaryProps.productsWithScores
      : props.history.push({
          pathname: "/search",
        });

  // const allItemsWithScores = props.location.summaryProps.productsWithScores;

  const [liked, setLiked] = useState([]);
  const [disliked, setDisliked] = useState([]);
  const [completeItems, setCompleteItems] = useState();
  const [error, setError] = useState("");
  const [showShopNow, setShowShopNow] = useState("false");
  const [loadingIcon, setLoadingIcon] = useState(false);

  const getLikedItems = useCallback((items) => {
    const likedItmes = items.filter((item) => +item.scores > 3);
    return likedItmes;
  }, []);

  const getDislikedItems = useCallback((items) => {
    const dislikedItmes = items.filter((item) => +item.scores <= 3);
    return dislikedItmes;
  }, []);

  useEffect(() => {
    initGA();
    PageView();
    setLiked(getLikedItems(allItemsWithScores));
    setDisliked(getDislikedItems(allItemsWithScores));
  }, []);

  function saveReasonforEachItem(r, p) {
    // console.log(allItemsWithScores);
    // console.log(r, p, currentUser.email);
    let newAllItems = allItemsWithScores;
    newAllItems.forEach((item) => {
      return item.product_uid === p.product_uid ? (item.reason = r) : null;
    });
    setCompleteItems(newAllItems);
  }

  async function sendData() {
    Event("Button", "Clicked Save preferences button", "Summary_Component");
    setLoadingIcon(true);
    const userInfo = {
      summary: {
        summary_uid: "",
        email: currentUser.email,
        created_at: new Date().toLocaleString(),
        product_payload: completeItems.map(
          ({ base_product_uid, product_uid, scores, reason }) => {
            // check if product_uid is exists
            if (product_uid && product_uid !== "") {
              return {
                base_product_uid,
                product_uid,
                score: +scores,
                reason,
                send_to_wishlist: 0,
              };
            }
          }
        ),
      },
    };

    await http
      .post("https://api.jointhesplash.com/userchoice", userInfo, {
        headers: { "Content-Type": "application/json" },
      })
      // .then((response) => console.log(response))
      // .then(() =>
      //   props.history.push({
      //     pathname: "/search",
      //   })
      // )
      .then(() => setShowShopNow(!showShopNow))
      .then(() => setLoadingIcon(false))
      .catch((err) => setError("Failed to save, please try again!"));
  }

  return (
    <Container className="summary">
      <h2></h2>
      <div className="alert alert-primary" role="alert">
        <Col style={{ textAlign: "center" }}>
          Save your preferences and reasons to unlock the shopping link on any
          item you like!
        </Col>
      </div>
      <h2>Your top favourites</h2>
      <Container>
        <Row>
          {liked.length > 0
            ? liked.map((product, index) => {
                return (
                  <Col
                    key={product.product_uid}
                    xs={12}
                    sm={12}
                    md={4}
                    className="mb-4"
                    style={{
                      boxShadow:
                        "0 0.125rem 0.8rem rgba(0, 0, 0, 0.075) !important",
                    }}
                  >
                    <Product
                      data={product}
                      style={{
                        fontSize: "0.7rem",
                      }}
                      img={{ width: "200px", height: "200px" }}
                    />
                    <Row
                      style={
                        showShopNow
                          ? { display: "none" }
                          : { marginBottom: "1rem" }
                      }
                    >
                      <Col style={{ textAlign: "center" }}>
                        <a
                          className="btn btn-outline-primary btn-sm"
                          target="_blank"
                          href={`//${product.url && product.url.slice(8)}`}
                          role="button"
                          onClick={() =>
                            Event(
                              "Button",
                              "Clicked Shop Now Button",
                              "Summary_Component"
                            )
                          }
                        >
                          Shop Now
                        </a>
                      </Col>
                    </Row>
                    <Dropdown
                      style={!showShopNow ? { display: "none" } : null}
                      title="Your reason"
                      reasons={likedReasons}
                      // multiSelect
                      handleSelectedReasons={(reason) =>
                        saveReasonforEachItem(reason, product)
                      }
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>

      <h2>Your least favourites</h2>
      <Container>
        <Row>
          {disliked.length > 0
            ? disliked.map((product, index) => {
                return (
                  <Col
                    key={product.product_uid}
                    xs={12}
                    sm={12}
                    md={4}
                    className="mb-4"
                    style={{
                      boxShadow:
                        "0 0.125rem 0.8rem rgba(0, 0, 0, 0.075) !important",
                    }}
                  >
                    <Product
                      data={product}
                      style={{
                        fontSize: "0.7rem",
                      }}
                      img={{ width: "200px", height: "200px" }}
                    />
                    <Row
                      style={
                        showShopNow
                          ? { display: "none" }
                          : { marginBottom: "1rem" }
                      }
                    >
                      <Col style={{ textAlign: "center" }}>
                        <a
                          className="btn btn-outline-primary btn-sm"
                          target="_blank"
                          href={`//${product.url && product.url.slice(8)}`}
                          role="button"
                          onClick={() =>
                            Event(
                              "Button",
                              "Clicked Shop Now Button",
                              "Summary_Component"
                            )
                          }
                        >
                          Shop Now
                        </a>
                      </Col>
                    </Row>
                    <Dropdown
                      style={!showShopNow ? { display: "none" } : null}
                      title="Your reason"
                      reasons={dislikedReasons}
                      // multiSelect
                      handleSelectedReasons={(reason) =>
                        saveReasonforEachItem(reason, product)
                      }
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
      <Row className="my-4">
        {!showShopNow ? (
          <Col style={{ textAlign: "center" }}>
            <div className="alert alert-primary" role="alert">
              You can either start new search by hitting the button below or
              click Shop Now on any item you like!
            </div>
            <Button
              variant="success"
              size="lg"
              type="submit"
              onClick={() => {
                Event(
                  "Button",
                  "Clicked Start new search Button",
                  "Summary_Component"
                );
                props.history.push({
                  pathname: "/search",
                });
              }}
              className="mt-2"
            >
              Start new search
            </Button>
          </Col>
        ) : (
          <Col style={{ textAlign: "center" }}>
            <h4>Save your preferences and you're done!</h4>
            <Button
              variant={!loadingIcon ? "success" : "secondary"}
              size="lg"
              type="submit"
              onClick={sendData}
              className={!loadingIcon ? "mt-2" : "mt-2 disabled"}
            >
              <span style={!loadingIcon ? { display: "none" } : null}>
                <i className="fas fa-spinner fa-spin mr-2"></i>
              </span>
              Save preferences
            </Button>
          </Col>
        )}
      </Row>
      <Row className="my-4 w-50 mx-auto">
        <Col className="">
          {error && (
            <div className="alert alert-danger mt-0 text-center" role="alert">
              {error}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
