import React, { Component } from "react";
//import Navbar from "./Navbar";
import Navigationbar from "./NavBootsrap";
import Search from "./Search";
import Results from "./Results";
import About from "./About";
import HowItWorks from "./HowItWorks";
import PrivateRoute from "./PrivateRoute";

// Login.js is depricated, use UserLogin
import UserLogin from "./UserLogin";
// Provider gives context(global app state) to ALL child components
// See Navbar component for example
import { AuthProvider } from "./utilities/authenticate";
import { initGA, PageView } from "./Tracking/index";

import Summary from "./Summary";

// react dom methods
import { HashRouter as Router, Route, Switch } from "react-router-dom";

//import logo from "./logo.svg";
//import "./App.css";

class App extends Component {
  componentDidMount() {
    initGA();
  }
  render() {
    return (
      <Router>
        <AuthProvider>
          <div className="App">
            <Navigationbar />
            <Switch>
              <Route
                exact
                path="/theSplashJourney"
                render={(props) => <About {...props} />}
              />
              <Route
                exact
                path="/howItWorks"
                render={(props) => <HowItWorks {...props} />}
              />
              <Route
                exact
                path="/"
                render={(props) => <UserLogin {...props} />}
              />
              <Route
                exact
                path="/login"
                render={(props) => <UserLogin {...props} />}
              />

              <PrivateRoute
                path="/search"
                component={(props) => <Search {...props} />}
              />
              <PrivateRoute
                path="/results"
                component={(props) => <Results {...props} />}
              />
              <PrivateRoute
                path="/summary"
                component={(props) => <Summary {...props} />}
              />
            </Switch>
          </div>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
