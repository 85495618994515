import React, { Component } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Product from "./utilities/Product";
import { AuthContext } from "./utilities/authenticate";

import { initGA, PageView, Event } from "./Tracking/index";

import http from "../services/httpService";

class Search extends Component {
  constructor(props) {
    super();

    this.state = {
      inputUrl: "",
      randomUrlInfo: "",
      loading: true,
      urlValid: true,
    };

    // regex to validate any url
    this.expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    // create regular expression
    this.regex = new RegExp(this.expression);

    // warning text to show when url to search is invalid
    this.warningText =
      "Sorry! We're only accepting valid URLs from THE ICONIC. Try https://www.theiconic.com.au/luana-mini-dress-1127806.html";
  }
  static contextType = AuthContext;

  fetchRandom = () => {
    const { currentUser } = this.context;

    // console.log(currentUser);
    const email = currentUser.email;
    const loginTime = currentUser.metadata.lastSignInTime;

    http
      .get(
        `https://api.jointhesplash.com/randurlinfo?email=${email}&login_ts=${loginTime}`
      )
      .then(({ data }) => {
        // console.log(data[1]);
        this.setState({
          randomUrlInfo: data[1],
        });
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  // function to use to check url
  checkUrl() {
    // must pass regex as a valid url AND include "theiconic.com.au"
    if (
      this.state.inputUrl.toLowerCase().match(this.regex) &&
      this.state.inputUrl.toLowerCase().includes("theiconic.com.au")
    ) {
      this.setState({
        urlValid: true,
      });
    } else {
      this.setState({
        urlValid: false,
      });
    }
  }

  // Check url against regex each time component reloads
  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputUrl !== this.state.inputUrl) {
      this.checkUrl();
    }
  }

  // check url against regex before component mounts the first time
  componentDidMount() {
    initGA();
    PageView(this.props.history.location.pathname);
    // this.checkUrl();
    this.fetchRandom();
  }

  handleSubmit = (event) => {
    // Event("Button", "Clicked Search Button", "Search_Component");
    event.preventDefault();
    // const originalUrl = this.state.inputUrl;
  };

  render() {
    return (
      <div>
        <Container className="mt-3">
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formUrl">
                  <Form.Label
                    className="mb-3"
                    style={{
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    <h3 style={{ fontWeight: "bold" }}>Paste your URL here</h3>
                  </Form.Label>

                  <Form.Control
                    value={this.state.inputUrl}
                    name="inputUrl"
                    type="url"
                    onChange={this.handleChange}
                    required
                    className="mb-4"
                    placeholder="Paste URLs for women’s clothing from the iconic or Missguided"
                  />
                </Form.Group>
                {this.state.urlValid ? (
                  <Link
                    className="btn btn-primary p-2 mx-auto "
                    style={{ display: "block", width: "10rem" }}
                    to={{
                      pathname: `/results`,
                      resultsProps: {
                        searchUrl: this.state.inputUrl,
                      },
                    }}
                    onClick={() =>
                      Event(
                        "Button",
                        "Clicked Search alternatives for URL above Button",
                        "Search_Component"
                      )
                    }
                  >
                    Search alternatives for URL above
                  </Link>
                ) : (
                  <div>
                    {/* <button
                      className="btn btn-primary p-2 mx-auto "
                      style={{ display: "block", width: "10rem" }}
                      disabled
                    >
                      Search Alternatives
                    </button> */}

                    <div className="alert alert-danger mt-2" role="alert">
                      {/* {this.warningText} */}

                      {this.state.urlValid === false ? this.warningText : null}
                    </div>
                  </div>
                )}

                {this.props.location.searchProps ? (
                  <div className="alert alert-danger mt-2" role="alert">
                    {this.props.location.searchProps
                      ? this.props.location.searchProps.errorMsg
                      : null}
                  </div>
                ) : null}
              </Form>
            </Col>
          </Row>

          <Row className="justify-content-center mt-4 ">
            <h3 style={{ fontWeight: "bold" }}>OR</h3>
          </Row>
          <Row className="justify-content-center my-3">
            <h3 style={{ fontWeight: "bold" }}>Seek Some Inspiration</h3>
          </Row>
          <Row className="justify-content-center my-3">
            {this.state.randomUrlInfo ? (
              <Col xs={10} md={8}>
                <Row className="justify-content-center mt-3">
                  <Product
                    data={this.state.randomUrlInfo}
                    img={{
                      borderRadius: "0.3rem",
                    }}
                    style={{
                      border: "1px solid black",
                      borderRadius: "0.3rem",
                    }}
                  />
                </Row>
                <Row className="justify-content-center mt-3">
                  <Link
                    className="btn btn-primary p-2"
                    style={{ display: "block", width: "10rem" }}
                    to={{
                      pathname: `/results`,
                      resultsProps: {
                        searchUrl: this.state.randomUrlInfo.input_url,
                      },
                    }}
                    onClick={() =>
                      Event(
                        "Button",
                        "Clicked Search alternatives for item above button",
                        "Search_Component"
                      )
                    }
                  >
                    Search alternatives for item above
                  </Link>
                </Row>
              </Col>
            ) : (
              <Container className="mt-3 text-center">
                <span
                  style={{
                    fontSize: "3rem",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  <i className="fas fa-sync fa-spin mb-4"></i>
                  <h6 className="animate__animated animate__zoomIn">
                    We're generating a random inspiration for you...
                  </h6>
                </span>
              </Container>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Search;
