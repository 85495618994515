import React, { useContext, useState, useEffect } from "react";
import firebase, { auth, signInWithGoogle } from "../../firebase";

/*
    Provides context, like a global state or store for the app.

    Exports a provider(value) which includes login and logout function, as well 
    as the currentUser object which is returned by google+firebase.

    How to use;
    import useAuth from "./utilities/authenticate";
    const {login, logout, currentUser} useAuth();

    Since AuthProvider component is used to wrap the Application(see App.js)
    login, logout, currentUser can be used by any component.
*/
export const AuthContext = React.createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function login() {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return auth.signInWithPopup(signInWithGoogle);
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        console.log("errorcode", errorCode);
        const errorMessage = error.message;
        console.log(("errorMessage", errorMessage));
      });
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
