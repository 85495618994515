import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./utilities/authenticate";

// Takes a component and checks if the currentUser(once logged in successfully)
// object is available, then provides access to the route, else redirects to the Login page.
export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
}
