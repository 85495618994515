import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useRef,
} from "react";

import Product from "./utilities/Product";
import Alternatives from "./Alternatives";
import { Container, Row } from "react-bootstrap";
// to get logged in user details
// import { useAuth } from "./utilities/authenticate";

import { initGA, PageView } from "./Tracking/index";

import http from "../services/httpService";
// import data from "../dataFiles/alternatives.json";
// const axios = require("axios");

function Results(props) {
  const url =
    props.location.resultsProps !== undefined
      ? props.location.resultsProps.searchUrl
      : props.history.push({
          pathname: "/search",
        });

  const [alternatives, setAlternatives] = useState([]);
  const [urlInfo, setUrlInfo] = useState({});

  const [loadingMessage, setLoadingMessage] = useState(
    `We're getting alternatives for you...`
  );

  const [loadingIcon, setLoadingIcon] = useState(true);
  const [isUrlLoading, setIsUrlLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;

  const getData = useCallback(async () => {
    try {
      if (url) {
        const { data: urlInfo } = await http.get(
          `https://api.jointhesplash.com/urlinfo?pageurl=%22${url}%22`
        );
        // const { data: urlInfo } = await axios.get(
        //   `https://api.jointhesplash.com/urlinfo?pageurl=%22${url}%22`
        // );
        // console.log("urlinfo", urlInfo);
        setUrlInfo(Object.values(urlInfo)[0]);
        setIsUrlLoading(false);

        // const { data: alternatives } = await axios.get(
        //   `https://api.jointhesplash.com/alternatives?pageurl=%22${url}%22`
        // );

        const { data: alternatives } = await http.get(
          `https://api.jointhesplash.com/alternatives?pageurl=%22${url}%22`
        );
        // console.log("alternatives");
        if (
          Object.values(alternatives.result[0].exact_matches[0]).length === 0
        ) {
          setAlternatives(alternatives.result[1].top_alternatives);
        } else {
          setAlternatives(
            alternatives.result[0].exact_matches.concat(
              alternatives.result[1].top_alternatives
            )
          );
        }

        setIsLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 501) {
          props.history.push({
            pathname: "/search",
            searchProps: { errorMsg: error.response.data.message },
          });
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        //console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        //console.log("Error", error.message);
      }
    }
  }, [url]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      return isLoadingRef.current
        ? setLoadingMessage(
            `We're experiencing more load than we expected. Just a bit more...`
          )
        : null;
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeout(() => {
        return isLoadingRef.current
          ? props.history.push({
              pathname: "/search",
            })
          : null;
      }, 5000);

      setLoadingIcon(false);
      return isLoadingRef.current
        ? setLoadingMessage(
            `Sorry about this! Come back in a short while OR how about trying on a new inspiration?`
          )
        : null;
    }, 25000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // console.log(props.history);
    initGA();
    PageView(props.history.location.pathname);
  }, []);

  return (
    <div>
      <div className="exactMatch mx-auto">
        {Object.values(urlInfo).length > 0 ? (
          <Fragment>
            <h3 style={{ textAlign: "center" }}>
              This is the product you are searching for
            </h3>
            <Container>
              <Row>
                <Product data={urlInfo} />
              </Row>
            </Container>
          </Fragment>
        ) : null}
      </div>

      {isLoading ? (
        <Container>
          <span
            style={{
              fontSize: "3rem",
              display: "block",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            <i className={loadingIcon ? "fas fa-sync fa-spin mb-4" : null}></i>
            <h5 className="animate__animated animate__zoomIn">
              {loadingMessage}
            </h5>
          </span>
        </Container>
      ) : (
        <div className="flex-column">
          {alternatives.length > 0 ? (
            <div className="alternatives  mt-5 mb-5">
              <h3 style={{ textAlign: "center" }}>
                We found these top alternatives for you
              </h3>
              <Alternatives data={alternatives} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Results;
