import { Navbar, Nav } from "react-bootstrap";
import { useAuth } from "./utilities/authenticate";

import { Event } from "./Tracking/index";

function Navigationbar() {
  const { currentUser, logout } = useAuth();
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="light"
      variant="light"
      className="sticky-top"
    >
      <Navbar.Brand
        href="/"
        className=""
        onClick={() => Event("Link", "Clicked Logo", "Navbar_Component")}
        style={{}}
      >
        <img
          className="logo-image"
          src="splash_logo_main.png"
          alt=""
          style={{}}
        />{" "}
        <span className="logo">The Splash</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <Nav.Link
            href="#/theSplashJourney"
            onClick={() =>
              Event(
                "Link",
                "Clicked The Splash Journey link",
                "Navbar_Component"
              )
            }
          >
            The Splash Journey
          </Nav.Link>

          <Nav.Link
            href="#/howItWorks"
            onClick={() =>
              Event("Link", "Clicked How it works link", "Navbar_Component")
            }
          >
            How it works
          </Nav.Link>
          <Nav.Link
            href="#/search"
            onClick={() =>
              Event("Link", "Clicked Search link", "Navbar_Component")
            }
          >
            Search
          </Nav.Link>
          {currentUser === null ? (
            <Nav.Link
              href="#/login"
              onClick={() =>
                Event("Link", "Clicked Login link", "Navbar_Component")
              }
            >
              Login
            </Nav.Link>
          ) : (
            <Nav.Link
              href="/"
              onClick={() => {
                Event("Link", "Clicked Logout Link", "Navbar_Component");
                logout();
              }}
            >
              Logout
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigationbar;
