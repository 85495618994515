import React, { useEffect } from "react";
import Emoji from "./utilities/Emoji";
import { Container, Row, Col } from "react-bootstrap";

import Step1 from "./Assets/Images/Step1.png";
import Step2 from "./Assets/Images/Step2.png";
import Step3 from "./Assets/Images/Step3.png";
import { initGA, PageView } from "./Tracking/index";

function HowItWorks(props) {
  useEffect(() => {
    initGA();
    PageView(props.history.location.pathname);
  }, []);
  return (
    <Container className="mx-auto">
      <Row className="mt-4 p-2">
        <Col
          style={{
            textAlign: "center",
            marginTop: "2rem",
            marginBottom: "3rem",
          }}
          lg={12}
        >
          <h2>The Splash (alpha) in 3 simple steps</h2>
        </Col>
        <Row style={{ marginBottom: "4rem" }}>
          <Col xs={2} sm={12} className="mr-0">
            <span
              className="mx-auto mb-2"
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "#bbb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              1
            </span>
          </Col>
          <Col xs={12} sm={12} md={6} id="img">
            <img
              style={{ width: "100%" }}
              className="d-block mx-auto mb-2 px-2"
              src={Step1}
            />
          </Col>
          <Col
            id="about"
            xs={10}
            sm={12}
            md={6}
            style={{ borderLeft: "1px solid #bbb" }}
          >
            <h4 className="mx-3 mb-2">
              Seek inspiration from an item that our algorithm randomly selects OR paste the URL of an item you like
            </h4>
          </Col>
        </Row>

        <Row style={{ marginBottom: "4rem" }}>
          <Col xs={2} sm={12} className="mr-0">
            <span
              className="mx-auto mb-2"
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "#bbb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              2
            </span>
          </Col>
          <Col sm={12} md={6} id="img">
            <img
              style={{ width: "100%" }}
              className="d-block mx-auto mb-2 px-2"
              src={Step2}
            />
          </Col>
          <Col
            id="about"
            xs={10}
            sm={12}
            md={6}
            style={{ borderLeft: "1px solid #bbb" }}
          >
            <h4 className="mx-3 mb-2">
              Navigate and Rate the 8 alternatives you receive
            </h4>
          </Col>
        </Row>

        <Row style={{ marginBottom: "4rem" }}>
          <Col xs={2} sm={12} className="mr-0">
            <span
              className="mx-auto mb-2"
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "#bbb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              3
            </span>
          </Col>
          <Col sm={12} md={6} id="img">
            <img
              style={{ width: "100%" }}
              className="d-block mx-auto mb-2 px-2"
              src={Step3}
            />
          </Col>
          <Col
            id="about"
            xs={10}
            sm={12}
            md={6}
            style={{ borderLeft: "1px solid #bbb" }}
          >
            <h4 className="mx-3 mb-3">
              Save your preferences once you're done with the 8 alternatives!
              That's it!
            </h4>
            <p className="mx-3">
              1. Start building your own preference centre <Emoji symbol="🎊" />
            </p>
            <p className="mx-3">
              2. Spoil yourself shopping this holiday season{" "}
              <Emoji symbol="🎁" />
            </p>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default HowItWorks;
