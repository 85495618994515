import React from "react";
import Rating from "react-rating";

function Dialbar({ onChange, value }) {
  function renderTextDisplay() {
    // console.log("current value", value);
    if (value === 1) {
      return <span className="badge bg-warning text-dark">Really dont like it as an alternative</span>;
    }
    if (value === 2) {
      return <span className="badge bg-warning text-dark">Not really relevant</span>;
    }
    if (value === 3) {
      return (
        <span className="badge bg-warning text-dark">Meh</span>
      );
    }
    if (value === 4) {
      return <span className="badge bg-success">Quite relevant</span>;
    }
    if (value === 5) {
      return <span className="badge bg-success">I love it as an alternative!</span>;
    }
  }

  return (
    <div className="form-group">
      <label
        htmlFor="formControlRange"
        className="d-flex justify-content-center"
      >
        <h5><b>What do you think of this alternative?</b></h5>
      </label>

      <div className="d-flex justify-content-center">{renderTextDisplay()}</div>
      <div className="d-flex justify-content-center">
        <Rating
          placeholderRating={value}
          onChange={(number) => onChange(number)}
        />
      </div>
    </div>
  );
}

export default Dialbar;
