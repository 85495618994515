import React from "react";

function Product({ data, style, img }) {
  const { offerPrice, merchant, image, brand, productName, url } = data;
  return (
    <div className="mx-auto d-flex flex-row" style={style ? style : null}>
      <div className="bd-highlight mb-3 w-100" style={{ flex: "1 1 50%" }}>
        <img
          src={`data:image/jpeg;base64,${image}`}
          className="bd-highlight img-fluid"
          alt="..."
          style={img ? img : { height: "auto", maxWidth: "100%" }}
        />
      </div>
      <div
        className="card-body p-2 bd-highlight"
        style={{ height: "auto", flex: "1 1 50%", overflow: "auto" }}
      >
        <h5 className="card-title">{brand}</h5>
        <p>{productName}</p>
        <p className="card-text">${offerPrice}</p>
        From&nbsp;
        {/* <a href={`//${url && url.slice(8)}`}> */}
        <b>{merchant}</b>
        {/* </a> */}
      </div>
    </div>
  );
}

export default Product;
