import firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // keys...
  apiKey: "AIzaSyAg00vfyDcOuI5zZM7WoIb66apYD8QDmN4",
  authDomain: "the-alt.firebaseapp.com",
  projectId: "the-alt",
  storageBucket: "the-alt.appspot.com",
  messagingSenderId: "319132063860",
  appId: "1:319132063860:web:5c5b5772fd03cf175dd921",
  measurementId: "G-HTCP27V3DC",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();
export default firebase;

//
export const auth = firebase.auth();

//export default firebase;
export const signInWithGoogle = new firebase.auth.GoogleAuthProvider();


