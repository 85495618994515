import Emoji from "./utilities/Emoji";
import Iconic from "./Assets/Images/Iconic.png";
import Missguided from "./Assets/Images/Missguided.png";
import Noel from "./Assets/Images/Noel.png";
import Dorsu from "./Assets/Images/Dorsu.png";
import SticksStones from "./Assets/Images/SticksStones.png";
import Vestire from "./Assets/Images/Vestire.jpeg";
import Forever21 from "./Assets/Images/Forever21.jpg";
import Bloomingdales from "./Assets/Images/Bloomingdales.png";
import ForeverNew from "./Assets/Images/ForeverNew.png";
import AliceMcCALL from "./Assets/Images/AliceMcCALL.png";
import BrasNThings from "./Assets/Images/BrasNThings.png";
import Zaful from "./Assets/Images/Zaful.png";
import Italist from "./Assets/Images/Italist.jpg";
import Forcast from "./Assets/Images/Forcast.png";
import Styletread from "./Assets/Images/Styletread.png";
import NewChic from "./Assets/Images/NewChic.png";
import HelloMolly from "./Assets/Images/HelloMolly.png";
import Bluecream from "./Assets/Images/Bluecream.png";
import Leonisa from "./Assets/Images/Leonisa.png";
import Bikinicom from "./Assets/Images/Bikinicom.png";
import Faire from "./Assets/Images/Faire.png";
import Boutuquefeel from "./Assets/Images/Boutuquefeel.png";
import SaltyCrush from "./Assets/Images/SaltyCrush.png";
import Lazyone from "./Assets/Images/Lazyone.png";
import Tulio from "./Assets/Images/Tulio.png";
import Kotn from "./Assets/Images/Kotn.png";
import PassionJilie from "./Assets/Images/PassionJilie.png";
import Recreate from "./Assets/Images/Recreate.png";
import AnnaCordell from "./Assets/Images/AnnaCordell.png";
import Dressarte from "./Assets/Images/Dressarte.png";
import Style from "./Assets/Images/Style.png";
import Erba from "./Assets/Images/Erba.png";
import Terra from "./Assets/Images/Terra.png";
import Tulsi from "./Assets/Images/Tulsi.png";

import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { initGA, PageView } from "./Tracking/index";

function About(props) {
  const style = {
    display: "list-item",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
  };

  useEffect(() => {
    initGA();
    PageView(props.history.location.pathname);
  }, []);
  return (
    <Container className="mx-auto">
      <Row className="mt-4 p-2">
        <Col style={{ textAlign: "center" }}>
          <h2 className="d-block mb-4">Welcome to the Splash (alpha)!</h2>
          <h4 className="d-block mb-4">
            What is the Splash <Emoji symbol="😋" />
          </h4>
          <p className="d-block main-text">
            <strong>The Splash</strong> is your go-to-platform to find
            alternatives across different stores for items you are shopping for
            online. Whether you are looking for a similar or majorly different
            inspiration we'll help you discover new items, new merchants and new
            alternatives, at the click of a button.
          </p>
          <h4 className="d-block mb-4">
            Why we are doing an exclusive Alpha <Emoji symbol="😃" />
          </h4>
          <p className=" sub-text">
            We want to make a product we know you will love and for that we are
            keeping things exclusive to a handful of users,
            <br /> YOU!
          </p>
          <p className=" sub-text">
            The Alpha will help us understand the kind alternatives that you
            like and find suitable so we can build the phase of The Splash.
            <br />
            (Psst, It's only a few months away!)
          </p>
        </Col>
      </Row>

      <hr
        className="solid"
        style={{ borderTop: "3px solid #bbb", height: "2rem", width: "50%" }}
      />

      <Row>
        <Col>
          <h2 style={{ textAlign: "center", marginBottom: "5rem" }}>
            Our first release partners <Emoji symbol="🎉" />
          </h2>
        </Col>
      </Row>
      <Container
        className="mx-auto"
        style={{ maxWidth: "750px", padding: "0 15px" }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            justifyContent: "center",
            padding: "0",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "0 auto 4rem auto",
          }}
        >
          <li style={style}>
            <span>
              <img src={Iconic} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Missguided} style={{ width: "200px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Noel} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Dorsu} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={SticksStones} style={{ width: "200px" }} />
            </span>
          </li>
        </ul>
      </Container>

      <hr
        className="solid"
        style={{ borderTop: "3px solid #bbb", height: "2rem", width: "50%" }}
      />

      <Row>
        <Col>
          <h2 style={{ textAlign: "center", marginBottom: "5rem" }}>
            Coming soon! <Emoji symbol="😍" />
          </h2>
        </Col>
      </Row>
      <Container
        className="mx-auto"
        style={{ maxWidth: "750px", padding: "0 15px" }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            justifyContent: "center",
            padding: "0",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "0 auto 4rem auto",
          }}
        >
          <li style={style}>
            <span>
              <img src={Forever21} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Bloomingdales} style={{ width: "200px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={ForeverNew} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={AliceMcCALL} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={BrasNThings} style={{ width: "200px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Vestire} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Zaful} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Italist} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Forcast} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Styletread} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={NewChic} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={HelloMolly} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Bluecream} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Leonisa} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Bikinicom} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Faire} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Boutuquefeel} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={SaltyCrush} style={{ width: "120px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Lazyone} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Tulio} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Kotn} style={{ width: "150px" }} />
            </span>
          </li>
        </ul>
      </Container>
      <hr
        className="solid"
        style={{ borderTop: "3px solid #bbb", height: "2rem", width: "50%" }}
      />
      <Row className="mx-auto">
        <Col className style={{ textAlign: "center" }}>
          <h2 style={{ marginBottom: "2rem", fontWeight: "bold" }}>
            We splashin' local and sustainable too!
          </h2>
          <p className="mb-5 main-text">
            The Splash is all about leveling up the playing field for brands to
            get noticed and be seen. We know the world is full of uniquely made
            and sourced items and we can't wait to bring them all to you.{" "}
          </p>
        </Col>
      </Row>
      <Container
        className="mx-auto"
        style={{ maxWidth: "750px", padding: "0 15px" }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            justifyContent: "center",
            padding: "0",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "0 auto 4rem auto",
          }}
        >
          <li style={style}>
            <span>
              <img src={SticksStones} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Dorsu} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={PassionJilie} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Noel} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Recreate} style={{ width: "100px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={AnnaCordell} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Dressarte} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Style} style={{ width: "150px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Erba} style={{ width: "110px" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Terra} style={{ width: "130px", paddingTop: "1rem" }} />
            </span>
          </li>
          <li style={style}>
            <span>
              <img src={Tulsi} style={{ width: "100px" }} />
            </span>
          </li>
        </ul>
      </Container>

      <footer style={{ marginTop: "5rem", textAlign: "center" }}>
        <hr className="solid" style={{ borderTop: "1px solid #bbb" }} />
        <img
          src="splash_logo_main.png"
          style={{ width: "3rem", height: "3rem" }}
        />
        <p
          style={{
            fontSize: "0.8rem",
            textAlign: "center",
            color: "#878f95",
            display: "inline-block",
          }}
        >
          Copyright &copy; 2020 All Rights Reserved.
        </p>
      </footer>
    </Container>
  );
}

export default About;
